export const TABLE_PAGE_SIZE = 30;
export const TABLE_PAGE_SIZE_ENCOUNTERS = 50;
export const TABLE_PAGE_SIZE_INVESTIGATIONS = 50;
export const TABLE_PAGE_SIZE_TEST_GROUPS = 50;
export const TABLE_PAGE_SIZE_TEST_PACKAGES = 50;
export const TABLE_PAGE_SIZE_PRESCRIPTION_REPORT = 50;
export const TABLE_PAGE_SIZE_PRESCRIPTION_REFILL = 50;
export const TABLE_PAGE_SIZE_ADMISSION = 50;
export const TABLE_PAGE_SIZE_LABTEST = 50;
export const TABLE_PAGE_SIZE_IDOVEN = 50;
export const DEFAULT_BRANCH_CODE = 'JABI0011';
export const DEFAULT_BRANCH_NAME = 'Abuja - Asba & Dantata';
export const ENCOUNTER = 'encounter';
export const ADMISSION = 'admission';
export const REACH_EMAIL = 'reach@eha.ng';
export const BMGF_LLM_STUDY = 'BMGF LLM Study';
export const CHART_REVIEW = 'chart_review';
export const DISEASE_PROCESS = 'disease_process_audit';
export const ANTIBIOTICS = 'antibiotics_audit';
export const PRESCRIPTION_AUDIT = 'prescription_audit';
export const MISSING = 'Missing';
export const COMPLETE = 'Complete';
export const YES = 'Yes';
export const NO = 'No';
export const COMPLETED = 'Completed';
export const PLANNED = 'Planned';
export const IN_PROGRESS = 'In Progress';
export const TO_DO = 'To Do';
export const REVIEW_SUMMARY = 'Review Summary';
export const NURSING_ASSESSMENT_REVIEW = 'Nursing Assessment Review';
export const DOCTOR_ASSESSMENT_REVIEW = 'Doctor Assessment Review';
export const PHARMACIST_ASSESSMENT_REVIEW = 'Pharmacist Assessment Review';
export const PRESCRIPTIONS = 'prescriptions';
export const PRESCRIPTION_REFILLS = 'prescriptionRefills';
export const DOCTOR = 'Doctor';
export const NURSE = 'Nurse';
export const PHARMACIST = 'Pharmacist';
export const NOT_APPLICABLE = 'Not Applicable';
export const MRN = 'MRN';
export const EHACARE_NURSE_GROUP_NAME = 'EHACare_nurse';
export const EHACARE_PHYSICIAN_GROUP_NAME = 'EHACare_physician';
export const WITH_NURSE_STAGE = 'with nurse';
export const WITH_DOCTOR_STAGE = 'with doctor';
export const READY_FOR_DOCTOR = 'ready for doctor';
export const PROCEDURE_TYPES_TERMINOLOGY_NAME = 'ProcedureList';
export const PATIENT_CONSENT = 'patient_consent';
export const BLOOD_TRANSFUSION_CONSENT = 'blood_transfusion_consent';
export const PROCEDURE_ORDERING_CONSENT = 'procedure_ordering_consent';
export const LABTEST_RETROVIRAL_CONSENT = 'labtest_retroviral_consent';
export const ENCOUNTER_TELEHEALTH_CONSENT = 'encounter_telehealth_consent';
export const TRANSITION_CONTROL_ENCOUNTER = 'Transition Control Encounter';
export const TRANSITION_CONTROL_PRESCRIPTION =
  'Transition Control Prescription';
export const TRANSITION_CONTROL_LABTEST = 'Transition Control Labtest';
