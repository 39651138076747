import { createSlice } from '@reduxjs/toolkit';

export interface UnSavedPrescription {
  id: number;
  isVisible: boolean;
  medicine: {
    code: string;
    label: string;
    terminology: string;
    value: string;
  }[];
  form: string[];
  routeOfAdministration: string[];
  additionalInstruction: string;
  dose: string;
  doseMagnitude: string;
  doseUnit: string;
  frequency: string;
  administrationDuration: string;
  infusionRate: { magnitude: string; unit: string };
  clinicalIndication: {
    code: string;
    label: string;
    terminology: string;
  }[];
  authorisationType: {
    code: string;
    value: string;
    label: string;
  }[];
  exactTiming: boolean;
  asRequired: boolean;
  maxNumberOfRefills: string;
  numberOfRefillsIssued: string;
  numberOfRefillsRemaining: string;
  minimumIntervalBetweenRefills: {
    month: string;
    week: string;
    day: string;
  };
  authorisationExpiryDate: string;
  nextRefillDate: string;
  dispenseInstruction: string;
  dispenseDate: string;
  prescriptionState: {
    code: string;
    value: string;
    label: string;
  }[];
  prescriptionItemState: {
    code: string;
    value: string;
    label: string;
  }[];
  orderId: string;
  isPrescriptionConditional: boolean;
  testConditions: string
}

export interface Prescription {
  isVisible: boolean;
  id: number;
  dispenseState?: {
    label: string;
    value: string;
  };
  pharmacistInstruction?: string;
  pharmacistComment?: {
    label: string;
    value: string;
  };
  customPharmacistComment?: string;
  Additional_instruction: {
    value: string;
  };
  Administration_duration: {
    value: string;
  };
  Admission_id: {
    '@class': string;
    value: string;
  };
  As_required: {
    '@class': string;
    value: boolean;
  };
  Authorisation_expiry_date: {
    '@class': string;
    value: string;
  };
  Authorisation_type: {
    '@class': string;
    defining_code: {
      '@class': string;
      terminology_id: {
        '@class': string;
        value: string;
      };
      code_string: string;
    };
    value: string;
  };
  Branch_code: {
    '@class': string;
    value: string;
  };
  Branch_name: {
    '@class': string;
    value: string;
  };
  Created_by: {
    '@class': string;
    value: string;
  };
  Clinical_indication: {
    '@class': string;
    defining_code: {
      '@class': string;
      code_string: string;
      terminology_id: {
        '@class': string;
        value: string;
      };
    };
    value: string;
  };
  Composition_uid_value?: string;
  Comment?: { value: string };
  Date_created: {
    '@class': string;
    value: string;
  };
  Dispense_by?: { value: string };
  Dispense_direction: {
    '@class': string;
    value: string;
  };
  Dispense_date: {
    '@class': string;
    value: string;
  };
  Dose: {
    '@class': string;
    magnitude: string;
    units: string;
  };
  Ehr_id: string;
  Encounter_id: {
    '@class': string;
    value: string;
  };
  Exact_timing_critical: {
    '@class': string;
    value: boolean;
  };
  Frequency: {
    '@class': string;
    value: string;
  };
  Form: {
    '@class': string;
    value: string;
  };
  Infusion_rate: {
    '@class': string;
    magnitude: string;
    units: string;
  };
  Maximum_number_of_refills: {
    '@class': string;
    magnitude: string;
  };
  Medication_item: {
    '@class': string;
    defining_code: {
      '@class': string;
      code_string: string;
      terminology_id: {
        '@class': string;
        value: string;
      };
    };
    value: string;
  };
  Membership_plan: {
    '@class': string;
    value: string;
  };
  Membership_status: {
    '@class': string;
    value: string;
  };
  Minimum_interval_between_refills: {
    value: string;
    month: number;
    week: number;
    day: number;
  };
  Number_of_refills_issued: {
    '@class': string;
    magnitude: string;
  };
  Number_of_refills_remaining: {
    '@class': string;
    magnitude: string;
  };
  Next_refill_date?: {
    '@class': string;
    value: string;
  };
  Prescription_id: string;
  Route_of_administration: {
    '@class': string;
    value: string;
  };
  Patient_age: {
    '@class': string;
    value: string;
  };
  Patient_id: {
    '@class': string;
    value: string;
  };
  Patient_name: {
    '@class': string;
    value: string;
  };
  Patient_dob: {
    '@class': string;
    value: string;
  };
  Patient_weight: {
    '@class': string;
    magnitude: string;
    units: string;
  };
  Prescribed_by: {
    '@class': string;
    value: string;
  };
  Prescriber_identifier: {
    '@class': string;
    value: string;
  };
  Prescribed_date: {
    '@class': string;
    value: string;
  };
  Prescription_item_state: {
    '@class': string;
    defining_code: {
      '@class': string;
      code_string: string;
      terminology_id: {
        '@class': string;
        value: string;
      };
    };
    value: string;
  };
  Prescription_state: {
    '@class': string;
    defining_code: {
      '@class': string;
      code_string: string;
      terminology_id: {
        '@class': string;
        value: string;
      };
    };
    value: string;
  };
  Order_id: {
    '@class': string;
    assigner: string;
    issuer: string;
    type: string;
    id: string;
  };
  Is_substituted: {
    value: boolean;
  };
  Substitution_reason: {
    value: string;
  };
  Is_prescription_conditional: {
    value: string;
  };
  Test_conditions: {
    value: string;
  }
}

type PrescriptionStateType = {
  prescriptions: Prescription[];
  editPrescriptionList: Prescription[];
  isAddPrescription: boolean;
  isEditPrescription: boolean;
  allergyModalState: boolean;
  addPrescriptionToast: boolean;
  updatePrescriptionToast: boolean;
  dispensePrescriptionToast: boolean;
  substitutePrescriptionToast: boolean;
};

const initialState: PrescriptionStateType = {
  prescriptions: [],
  editPrescriptionList: [],
  isAddPrescription: false,
  isEditPrescription: false,
  allergyModalState: true,
  addPrescriptionToast: false,
  updatePrescriptionToast: false,
  dispensePrescriptionToast: false,
  substitutePrescriptionToast: false,
};

const prescriptionSlice = createSlice({
  name: 'prescriptions',
  initialState,
  reducers: {
    updatePrescription: (state, { payload }) => {
      state.prescriptions = payload;
    },

    removePrescriptions: (state, { payload }) => {
      state.prescriptions = state.prescriptions.filter(
        (eachPrescription) =>
          eachPrescription.Composition_uid_value !== payload,
      );
    },

    updateEditPrescriptionList: (state, { payload }) => {
      state.editPrescriptionList = payload;
    },
    openAddPrescriptionForm: (state) => {
      state.isAddPrescription = true;
    },
    openEditPrescriptionForm: (state) => {
      state.isEditPrescription = true;
    },
    updateAllergyModalState: (state, { payload }) => {
      state.allergyModalState = payload;
    },
    closePrescriptionForm: (state) => {
      state.isAddPrescription = false;
      state.isEditPrescription = false;
    },
    addPrescriptionToastFunc: (state, { payload }) => {
      state.addPrescriptionToast = payload;
    },
    updatePrescriptionToastFunc: (state, { payload }) => {
      state.updatePrescriptionToast = payload;
    },
    dispensePrescriptionToastFunc: (state, { payload }) => {
      state.dispensePrescriptionToast = payload;
    },
    toggleSubstitutePrescriptionToast: (state, { payload }) => {
      state.substitutePrescriptionToast = payload;
    },
    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updatePrescription,
  updateEditPrescriptionList,
  openAddPrescriptionForm,
  openEditPrescriptionForm,
  closePrescriptionForm,
  clearState,
  removePrescriptions,
  updateAllergyModalState,
  addPrescriptionToastFunc,
  updatePrescriptionToastFunc,
  dispensePrescriptionToastFunc,
  toggleSubstitutePrescriptionToast,
} = prescriptionSlice.actions;
export default prescriptionSlice.reducer;
